import React, { Component } from 'react';
import styled, { css } from 'react-emotion';
import { Above } from '@jetshop/ui/Breakpoints';
import { SearchField } from '@jetshop/ui/Search';
import { ReactComponent as SearchIcon } from '../../../svg/search-icon-v2.svg';
import AutocompleteQuery from './AutocompleteQuery.gql';

const Wrapper = styled('div')`
  display: flex;
  align-self: stretch;
  flex-direction: row;

  .ref {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  ${({ theme }) => theme.above.md} {
    padding: 0 2.5rem;
    border: 1px solid rgba(117, 126, 127, 0.3);
    border-radius: ${({ theme }) => theme.radius.md};
    width: 100%;
    max-width: 500px;
  }

  ${({ theme }) => theme.below.md} {
    transition: all 250ms ease;
    position: absolute;
    top: 50%;
    right: 0px;
    width: ${props => `${props.vw}px`};
    max-width: 64px;
    height: 55px;
    transform: translate(0px, -50%);

    ${props =>
      props.open &&
      css`
        max-width: calc(${props.vw}px - 30px);
        transform: translate(128px, -50%);
      `}
  }

  ${({ theme }) => theme.below.sm} {
    ${props =>
      props.open &&
      css`
        max-width: calc(${props.vw}px - 20px);
      `}
  }
`;

export const Label = styled('label')`
  color: ${({ theme }) => theme.colors.steelGray};
  text-transform: capitalize;
  font-weight: 700;
  direction: rtl;

  ${({ theme }) => theme.above.md} {
    width: 24px;
  }
`;

export const IconWrapper = styled('div')`
  width: 22px;
  height: 22px;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  svg {
    height: 22px;
    width: 22px;
    background: transparent;

    path {
      stroke: ${({ theme }) => theme.colors.steelGray};
    }

    ${({ theme }) => theme.below.md} {
      cursor: pointer;
    }
  }
`;

const StyledSearchField = styled(SearchField)`
  max-width: 100%;
  justify-content: flex-end;
  flex-grow: 1;

  ${SearchField.Wrapper} {
    width: 100%;
    transition: all 250ms ease;
    padding-right: 20px;

    ${({ theme }) => theme.below.md} {
      max-width: ${({ open, theme, vw }) => (open ? `${vw}px` : '0px')};
      padding-right: ${({ open }) => (open ? '20px' : '0px')};
    }

    ${({ theme }) => theme.above.md} {
      padding: 0px 15px;
    }

    input {
      width: 100%;
      height: 100%;
      padding: 0px;
      border: 0;
      outline: 0;
      box-shadow: none;
      border-radius: 0px;
      border-bottom: 2px solid ${({ theme }) => theme.colors.steelGray};
      background: transparent;
      ${({ theme }) => theme.fontSizes.description}
      transition: all 250ms ease;
    }
  }

  ${SearchField.Cancel} {
    display: none;
  }

  ${SearchField.Flyout} {
    top: 25px;
    text-align: left;

    li {
      &:not(:first-of-type) {
        border-top: 1px solid ${({ theme }) => theme.colors.lightGrey};
      }
    }

    a {
      display: block;
      padding: 10px 15px;
      color: ${({ theme }) => theme.colors.black};
      transition: all 250ms ease;

      &:hover {
        background-color: ${({ theme }) => theme.colors.lightGrey};
      }
    }
  }
`;

class SearchBar extends Component {
  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
    window.addEventListener('click', this.handleClick);
    window.addEventListener('keyup', this.handleKeyUp);

    let vw = document.body.clientWidth;

    this.setState({
      vw: vw
    });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
    window.removeEventListener('click', this.handleClick);
    window.removeEventListener('keyup', this.handleKeyUp);
  }

  state = {
    vw: 400
  };

  handleResize = e => {
    let vw = document.body.clientWidth;

    this.setState({
      vw: vw
    });
  };

  handleClick = e => {
    if (this.props.open) {
      let closeSearch = !this.ref.contains(e.target);

      if (closeSearch) {
        this.toggleSearch();
      }
    }
  };

  handleKeyUp = e => {
    if (this.props.open) {
      //escape key
      let closeSearch = e.keyCode === 27;

      if (closeSearch) {
        this.toggleSearch();
      }
    }
  };

  toggleSearch = () => {
    const open = !this.props.open;
    let input = this.ref.querySelector('input');

    this.setState({
      open: open
    });

    if (this.props.onToggleSearch) {
      this.props.onToggleSearch(open);
    }

    if (open) {
      input.focus();
    } else {
      window.blur();
    }
  };

  render() {
    return (
      <Wrapper open={this.props.open} vw={this.state.vw}>
        <div className="ref" ref={r => (this.ref = r)}>
          <Above breakpoint="md">
            {matches => (matches ? <Label>Sök</Label> : null)}
          </Above>

          <StyledSearchField
            autocompleteQuery={AutocompleteQuery}
            open={this.props.open}
            vw={this.state.vw}
          />

          <IconWrapper>
            <SearchIcon onClick={this.toggleSearch} />
          </IconWrapper>
        </div>
      </Wrapper>
    );
  }
}

export default SearchBar;
